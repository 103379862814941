var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShow
        ? _c(
            "div",
            {
              staticClass: "application-wrap",
              staticStyle: { "min-height": "500px" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.close(false)
                    },
                  },
                },
                [_vm._v(" 닫기 ")]
              ),
              _c("h1", [_vm._v("개인정보 수집·이용 안내")]),
              _c("p", { staticClass: "s-title mgt20" }, [
                _vm._v(
                  " 이벤트 당첨 시 경품 지급을 위해 개인정보를 입력해주세요. "
                ),
              ]),
              _c("div", { staticClass: "aw-tb" }, [
                _c("table", [
                  _vm._m(0),
                  _c("tr", [
                    _c("th", [_vm._v("이름")]),
                    _c("td", [
                      _c("div", { staticClass: "text-wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputName,
                              expression: "inputName",
                            },
                          ],
                          attrs: { type: "text", readonly: "", disabled: "" },
                          domProps: { value: _vm.inputName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.inputName = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("휴대전화번호")]),
                    _c("td", [
                      _c("div", { staticClass: "text-wrap" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputPhoneNum,
                              expression: "inputPhoneNum",
                            },
                          ],
                          attrs: { type: "text", disabled: _vm.isVerified },
                          domProps: { value: _vm.inputPhoneNum },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.inputPhoneNum = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(1),
              _vm._m(2),
              _c("div", { staticClass: "checkbox-wrap mgt20" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isPrivacyConfirm,
                      expression: "isPrivacyConfirm",
                    },
                  ],
                  staticClass: "application-type",
                  attrs: { id: "che2", type: "checkbox", name: "agreeType" },
                  domProps: {
                    checked: Array.isArray(_vm.isPrivacyConfirm)
                      ? _vm._i(_vm.isPrivacyConfirm, null) > -1
                      : _vm.isPrivacyConfirm,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isPrivacyConfirm,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isPrivacyConfirm = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isPrivacyConfirm = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isPrivacyConfirm = $$c
                      }
                    },
                  },
                }),
                _c("label", { staticClass: "f16", attrs: { for: "che2" } }, [
                  _vm._v("개인정보 수집 및 이용에 동의합니다."),
                ]),
              ]),
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-application",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.submit($event)
                      },
                    },
                  },
                  [_vm._v(" 동의하기 ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "128px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "txt-red" }, [
      _vm._v(
        " ※ 제출하신 개인정보는 수정이 불가능하며, 이벤트 경품은 이벤트 참여 시 입력하신"
      ),
      _c("br"),
      _vm._v("휴대폰번호로 발송됩니다. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agree-box collecting-type" }, [
      _c("dl", { staticClass: "mgt0" }, [
        _c("dt", [_vm._v("[필수] 개인정보 수집 및 이용 동의")]),
        _c("dd", [
          _c("div", [
            _c("ol", [
              _c("li", [
                _c("span", [_vm._v("1.")]),
                _vm._v(
                  "수집 이용목적 : 이벤트 당첨 결과 안내, 이벤트 참여 시 댓글 창 內 참여 이력 노출"
                ),
              ]),
              _c("li", [
                _c("span", [_vm._v("2.")]),
                _vm._v("수집 이용항목 : 이벤트 참여자의 이름, 휴대폰번호"),
              ]),
              _c("li", [
                _c("span", [_vm._v("3.")]),
                _vm._v("보유 및 이용 기간 : 보상지급일로부터 3개월까지"),
              ]),
              _c("li", [
                _c("span", [_vm._v("4.")]),
                _vm._v(
                  "개인정보 수집 및 이용 동의에 거부할 수 있으나, 거부할 경우 이벤트"
                ),
                _c("br"),
                _vm._v("참여 및 보상 지급이 불가합니다."),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }