<template>
  <div>
    <div
      v-if="isShow"
      class="application-wrap"
      style="min-height: 500px"
    >
      <button
        class="btn-close"
        @click.stop="close(false)"
      >
        닫기
      </button>
      <h1>개인정보 수집·이용 안내</h1>
      <p class="s-title mgt20">
        이벤트 당첨 시 경품 지급을 위해 개인정보를 입력해주세요.
      </p>
      <div class="aw-tb">
        <table>
          <colgroup>
            <col style="width:128px ">
            <col style="width:auto ">
          </colgroup>
          <tr>
            <th>이름</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="inputName"
                  type="text"
                  readonly
                  disabled
                >
              </div>
            </td>
          </tr>
          <tr>
            <th>휴대전화번호</th>
            <td>
              <div class="text-wrap">
                <input
                  v-model="inputPhoneNum"
                  type="text"
                  :disabled="isVerified"
                >
              </div>
            </td>
          </tr>
        </table>
      </div>

      <p class="txt-red">
        ※ 제출하신 개인정보는 수정이 불가능하며, 이벤트 경품은 이벤트 참여 시 입력하신<br>휴대폰번호로 발송됩니다.
      </p>

      <div class="agree-box collecting-type">
        <dl class="mgt0">
          <dt>[필수] 개인정보 수집 및 이용 동의</dt>
          <dd>
            <div>
              <ol>
                <li><span>1.</span>수집 이용목적 : 이벤트 당첨 결과 안내, 이벤트 참여 시 댓글 창 內 참여 이력 노출</li>
                <li><span>2.</span>수집 이용항목 : 이벤트 참여자의 이름, 휴대폰번호</li>
                <li><span>3.</span>보유 및 이용 기간 : 보상지급일로부터 3개월까지</li>
                <li><span>4.</span>개인정보 수집 및 이용 동의에 거부할 수 있으나, 거부할 경우 이벤트<br>참여 및 보상 지급이 불가합니다.</li>
              </ol>
            </div>
          </dd>
        </dl>
      </div>

      <div class="checkbox-wrap mgt20">
        <input
          id="che2"
          v-model="isPrivacyConfirm"
          type="checkbox"
          class="application-type"
          name="agreeType"
        >
        <label
          for="che2"
          class="f16"
        >개인정보 수집 및 이용에 동의합니다.</label>
      </div>

      <div class="btn-wrap">
        <button
          class="btn-application"
          @click.stop="submit"
        >
          동의하기
        </button>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { getUserLoginInfo } from '@/common/userInfo/service'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      inputName: null,
      inputPhoneNum: null,
      completeEvent: false,
      historyCount: 0,
      isVerified: false,
      isPrivacyConfirm: false,
      isProcessing: false,
      isShow: false,
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName',
      phoneNum: 'account/getPhoneNum',
      ysrUserName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  methods: {
    async call() {
      await this.checkedLogin()

      const historyCount = await this.fetchEventHistory()
      if (historyCount === null) return // 오류
      if (historyCount > 0) {
        this.showAlertDialog('이벤트에 이미 참여하셨습니다.')
        return
      }
      this.completeEvent = false // historyCount가 0이므로 이벤트 완료 여부를 다시 false로 변경

      if (this.userName) this.inputName = this.userName
      else if (this.ysrUserName) this.inputName = this.ysrUserName // 비회원일 때

      if (this.phoneNum && this.phoneNum.length > 0) {
        this.isVerified = true
        this.inputPhoneNum = this.phoneNum
      }

      this.isShow = true
    },

    async checkedLogin() {
      let isLoggedIn = false

      if (this.join) isLoggedIn = await isUserLoggedIn()
      else isLoggedIn = true // 비회원인 경우

      if (!isLoggedIn) {
        this.$router.push({ name: 'login-main', query: { redirect: this.$route.name } })
      }
    },

    async fetchEventHistory() {
      try {
        const rs = await axios.get(`/fu/event/hiscount/${this.eventId}`, {
          params: {
            userHash: this.shaEnLicenseNum,
            isRewardFin: false
          },
        })
        return rs.data // 참여횟수
      } catch (err) {
        this.showAlertDialog('이벤트 참여 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.')
        return null
      }
    },

    submit() {
      if (this.isProcessing) return
      if (this.join && !this.userName) this.redirectLogin()

      if (!this.inputPhoneNum) {
        this.showAlertDialog('이벤트 당첨 시 경품 지급을 위해 휴대전화번호를 입력해 주세요.')
        return
      }
      const phoneNumberRegex = /^\d{3,4}-\d{3,4}-\d{4}$/
      if (!this.inputPhoneNum.match(phoneNumberRegex)) {
        this.showAlertDialog('입력하신 휴대전화번호가 올바르지 않습니다.')
        return
      }
      if (!this.isPrivacyConfirm) {
        this.showAlertDialog('개인정보 이용 및 수집에 동의해 주세요.')
        return
      }

      // 이벤트 참여 등록
      this.createEventHistory()
    },

    createEventHistory() {
      this.isProcessing = true

      if (!this.completeEvent) {
        axios.post('/fu/event/history', {
          eventId: this.eventId,
          userPhoneNumber: this.inputPhoneNum,
          userName: this.inputName,
          medicalDept: this.medicalDept,
          userHash: this.shaEnLicenseNum
        })
          .then(() => {
            setStatistics(121, this.eventId)

            this.isProcessing = false
            this.completeEvent = true

            this.close(true)
          })
          .catch(() => {
            this.isProcessing = false
            this.showAlertDialog('이벤트 처리 진행 중 오류가 발생했습니다.\n관리자에게 문의하여 주시기 바랍니다.')
          })
      } else {
        this.showAlertDialog('이벤트에 이미 참여하셨습니다.')
        this.isProcessing = false
      }
    },

    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: this.$route.name } }).catch(() => { })
    },

    close(isConfirm) {
      this.isShow = false

      this.$emit('close', isConfirm)
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  margin: 0 auto;
}

area:hover {
  cursor: pointer;
}

.event-board {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}
</style>
